<template>
  <el-dialog
    class="select-dialog"
    :title="$t('common.select') + $t('company.company')"
    :visible.sync="dialogVisible"
    width="80%"
    top="2vh"
    append-to-body>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="companies">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('company.type')">
          <el-select v-model.trim="search.type" :placeholder="$t('common.pleaseSelect')" style="width: 100%" clearable>
            <el-option
              v-for="item in companyTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column prop="no" :label="$t('company.no')" :width="100"></table-column>
      <table-column :label="$t('company.type')" align="center" :width="100">
        <template #default="scope">
          {{ getTypeName(scope.row.type) }}
        </template>
      </table-column>
      <table-column prop="name" :label="$t('company.name')"></table-column>
      <table-column prop="phone" :label="$t('company.phone')" :width="100"></table-column>
      <table-column prop="abbreviation" :label="$t('company.abbreviation')" :width="100"></table-column>
      <table-column v-if="false" prop="brand" :label="$t('company.brand')" :width="60"></table-column>
      <table-column v-if="showOrgId" prop="orgName" :label="$t('elevator.belongCompany')" :width="180"></table-column>
      <table-column v-if="showOrgId" prop="tenantName" :label="$t('user.tenant')" :width="180"></table-column>
      <table-column prop="createTime" :label="$t('msgPushRecord.createTime')" :width="160"></table-column>
      <table-column :label="$t('common.operation')" :width="60" align="center" :tooltip="false">
        <template #default="scope">
          <el-button type="text" class="successBtn" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </table-column>
    </vm-table>
  </el-dialog>
</template>

<script>
  import { companyType } from "@/util/constant";
  import { companyType_en } from "@/util/constant";
  import auth from "@/util/auth";

  export default {
    data() {
      return {
        showOrgId: auth.getUsername() === "superAdmin" || auth.getTenantAdmin() === "true",
        dialogVisible: false,
        loading: true,
        extra: null,
        search: {
          filter: "",
          type: "",
          isUseUnit: 0
        },
        companyTypeList: [
          {value: 1, label: this.$t("company.manufacturerCompany")},
          {value: 2, label: this.$t("company.maintenanceCompany")},
          {value: 3, label: this.$t("company.installationCompany")},
          {value: 5, label: this.$t("company.propertyCompany")},
          {value: 6, label: this.$t("company.modificationCompany")},
          {value: 8, label: this.$t("company.useUnit")},
          {value: 9, label: this.$t("elevator.agent")},
        ],
      };
    },
    methods: {
      open(type, extra, filter) {
        if (type) {
          this.search.type = type;
        }
        this.dialogVisible = true;
        this.extra = extra;
        this.$nextTick(() => {
          if (filter) {
            this.search.filter = filter;
          }
          this.getList(1);
        });
      },
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      getTypeName(type) {
        if (this.$i18n.isCn) {
          return companyType[type];
        } else {
          return companyType_en[type];
        }
      },
      handleSelect(row) {
        this.$emit("select", row, this.extra);
        this.dialogVisible = false;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
